import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconWarning = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 5.20101 16.799 0.5 11 0.5C5.20101 0.5 0.5 5.20101 0.5 11C0.5 16.799 5.20101 21.5 11 21.5ZM9.6875 12.3125V4.4375H12.3125V12.3125H9.6875ZM9.6875 17.5625V14.9375H12.3125V17.5625H9.6875Z"
      fill="currentColor"
    />
  </SvgIcon>
)
