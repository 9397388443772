import {
  TBusinessAccountStatus,
  TBusinessMemberRole,
  TUserRole,
} from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  string,
  type,
  TypeOf,
} from 'io-ts'
import { UUID } from 'io-ts-types'

export const TUserResponse = intersection([
  type({
    userId: UUID,
    active: boolean,
  }),
  partial({
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    address: string,
    companyName: string,
    businessName: string,
    businessId: UUID,
    businessRole: TBusinessMemberRole,
    status: TBusinessAccountStatus,
    discountMinutes: number,
  }),
])

export const TMyProfile = intersection([
  TUserResponse,
  type({
    role: TUserRole,
    scopes: array(string),
    registerBySocials: boolean,
  }),
])

export type MyProfile = TypeOf<typeof TMyProfile>
