import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconWiFi = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M0 7.58336C3.18454 4.73312 7.38987 3 12 3C16.6101 3 20.8155 4.73312 24 7.58336V10.5L22.6991 11.9867C19.9777 9.21747 16.1894 7.5 12 7.5C7.8106 7.5 4.02234 9.21747 1.30088 11.9867L0 10.5V7.58336Z"
        fill="currentColor"
      />
      <path
        d="M3.28341 14.2525L10.5 22.5H13.5L20.7166 14.2525C18.5293 11.9416 15.433 10.5 12 10.5C8.56699 10.5 5.47068 11.9416 3.28341 14.2525Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
