import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconEmail = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.00024 9.32226V20H21.0002V9.32226L13.8463 15H10.1542L3.00024 9.32226Z"
        fill="currentColor"
      />
      <path
        d="M21.0002 6.25V5H3.00024V6.25L10.8752 12.5H13.1252L21.0002 6.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
