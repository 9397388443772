import { createTheme, outlinedInputClasses } from '@mui/material'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'outlined-secondary': true
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#215250',
      light: '#4CAAA4',
      dark: '#203434',
    },
    secondary: {
      main: '#F1691A',
      light: '#EB833D',
    },
    error: {
      main: '#BF2600',
      dark: '#98280C',
    },
    info: {
      main: '#1E3C3C',
      contrastText: '#C5C5C5',
    },
    success: {
      main: '#3E8D90',
    },
    background: {
      default: '#FFF',
    },
    text: {
      primary: '#0B0B0B',
      secondary: '#808080',
      disabled: '#4A4A4A',
    },
  },

  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },

  // @TODO: clean up typography
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    h1: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 'inherit',
    },
    h2: {
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: 'inherit',
    },
    h3: {
      fontSize: '24px',
      lineHeight: '34px',
      fontWeight: 'inherit',
    },
    h4: {
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: 'inherit',
    },
    h5: {
      fontWeight: 'inherit',
    },
    h6: {
      fontWeight: 'inherit',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'inherit',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'inherit',
    },
    caption: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'inherit',
    },
    subtitle1: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'inherit',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },

  // @TODO: why use !important?
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#FFF',
          // Keep old body typography
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: 0,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        outlined: {
          borderRadius: '4px',
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.04)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
        size: 'small',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '40px',
        },
        multiline: {
          height: 'auto',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '1px',
            borderRadius: '4px',
            borderColor: '#E5E5E5',
          },
          '&.Mui-disabled': {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#E5E5E5',
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        root: {
          borderRadius: '24px',
          lineHeight: '24px',
          padding: '14px 24px',
          height: '40px',
          fontSize: '16px',
          minWidth: 'auto',
        },
        outlined: {
          border: '1px solid #203434',
          color: '#203434',
          background: '#FFF',
          '&:hover': {
            borderColor: '#497676',
            color: '#497676',
          },
          '&& .MuiTouchRipple-child': {
            borderColor: '#142121',
            color: '#142121',
          },
        },
        contained: {
          boxShadow: 'none',

          ':hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: '48px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined-secondary' },
          style: {
            border: '1px solid #FFF',
            color: '#FFF',
            padding: '14px 24px',

            '&:hover': {
              color: '#0B0B0B',
            },
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0 !important',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    // Client-side only rendering
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },
})
