import { TUserResponse } from 'api/auth/user'
import { intersection, number, partial, string, type } from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TFeedback = intersection([
  type({
    feedbackId: string,
    rate: number,
    createdAt: DateFromISOString,
  }),
  partial({ userResponse: TUserResponse, comment: string }),
])
