import {
  Box,
  buttonClasses,
  CircularProgress,
  ExtendButtonBase,
  ExtendButtonBaseTypeMap,
} from '@mui/material'
import MuiButton, {
  ButtonClassKey,
  ButtonTypeMap as MuiButtonTypeMap,
} from '@mui/material/Button'
import { ElementType, forwardRef } from 'react'

type ButtonTypeMap<
  P = {},
  D extends ElementType = 'button',
> = ExtendButtonBaseTypeMap<{
  props: P &
    MuiButtonTypeMap['props'] & {
      /**
       * Disable the button and show a spinner instead of a button text.
       * It is meant to be used instead of the "disabled" state for asynchronous actions during which the button should be disabled to prevent multiple execution.
       */
      pending?: boolean
    }
  defaultComponent: D
  classKey: ButtonClassKey
}>

type ButtonType = ExtendButtonBase<ButtonTypeMap>

export const Button = forwardRef<any, any>(
  ({ pending = false, disabled = false, children, ...props }, ref) => {
    return (
      <MuiButton
        ref={ref}
        {...props}
        disabled={disabled || pending}
        sx={{
          ...(props.color === 'primary' && {
            backgroundColor: 'primary.light',
            color: '#FFF',

            '&:hover': {
              backgroundColor: '#5FB8B2',
            },
            '&& .MuiTouchRipple-child': {
              backgroundColor: '#429590',
            },
          }),

          [`&.${buttonClasses.disabled}`]: {
            background: '#E5E5E5 !important',
          },

          ...props.sx,
        }}
      >
        {pending && (
          <Box
            sx={{
              position: 'absolute',
              visibility: 'visible',
              display: 'flex',
              left: '50%',
              transform: 'translate(-50%)',
            }}
          >
            <CircularProgress color="inherit" size={16} />
          </Box>
        )}

        <Box
          component="span"
          display="flex"
          visibility={pending ? 'hidden' : 'visible'}
          fontSize="14px"
          letterSpacing="0.05em"
        >
          {children}
        </Box>
      </MuiButton>
    )
  },
) as ButtonType
