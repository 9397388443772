import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconBusiness = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M1.66666 6.2959H18.3337V18.3332H1.66666V6.2959Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37372 1.6665H14.6332V6.29623H12.4285V4.1032H7.57835V6.29623H5.37372V1.6665Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
