import { createSvgIcon } from '@mui/material'

export const IconEyeOpen = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10.625C3 10.625 6.375 5 12 5C17.625 5 21 10.625 21 10.625V12.875C21 12.875 17.625 18.5 12 18.5C6.375 18.5 3 12.875 3 12.875V10.625ZM12 15.125C13.864 15.125 15.375 13.614 15.375 11.75C15.375 9.88604 13.864 8.375 12 8.375C10.136 8.375 8.625 9.88604 8.625 11.75C8.625 13.614 10.136 15.125 12 15.125Z"
      fill="#E5E5E5"
    />
  </g>,
  'IconEyeOpen',
)
