import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
} from 'api/list'
import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, get, post } from 'lib/request'

import { TPod, TPodList, TPodListBasicInfo } from './booth.codecs'

export type GetBoothDetailsInput = {
  boothId: UUID
}

export const getBoothDetails = async (input: GetBoothDetailsInput) => {
  return pipe(
    await get('api/stuff/booths/:boothId', {
      params: {
        boothId: input.boothId,
      },
    }),
    decodeJson(TPod),
  )
}

export type RequestType =
  | 'BOOTH_NAME'
  | 'BOOTH_LOCATION'
  | 'CITY'
  | 'QUANTITY_OF_PERSONS'

export type GetBoothsListInput = {
  pagination: PaginationInput
  sort?: SortInput
  body: Record<string, any>
}

export const getBoothsList = async ({
  pagination,
  sort,
  body,
}: GetBoothsListInput) => {
  const query = createListQueryParams({
    pagination: pagination,
    sort: sort,
  })

  return pipe(
    await post('api/stuff/booths/list', { query, body }),
    decodeJsonWithTotal(TPod),
  )
}

const getNormalizedLongitude = (longitude: number) => {
  return longitude % 180
}

export type GetBoothsListByGeoInput = {
  lat: number
  lon: number
  radius?: number
}

export const getBoothsListByGeo = async ({
  lat,
  lon,
  radius,
}: GetBoothsListByGeoInput) => {
  const minRadius = 1

  return pipe(
    await get('api/stuff/booths/geo', {
      query: new URLSearchParams({
        lat: radius ? lat.toFixed(2) : lat.toString(),
        lon: radius ? getNormalizedLongitude(lon).toFixed(2) : lon.toString(),
        delta:
          radius === undefined
            ? '0.001'
            : radius > minRadius
            ? radius.toFixed(2)
            : minRadius.toString(),
      }),
    }),
    decodeJson(TPodList),
  )
}

export type GetBoothBasicInfo = {
  pagination: PaginationInput
  search?: string
}

export const getBoothsBasicInfo = async ({
  pagination,
  search,
}: GetBoothBasicInfo) => {
  const query = createListQueryParams({ pagination })

  if (search) query.set('search', search)

  return pipe(
    await get('api/stuff/booths/basic', {
      query,
    }),
    decodeJson(TPodListBasicInfo),
  )
}
