import { BusinessMemberRole } from 'api'
import { routes } from 'app/routes'
import { ConfirmationDialog } from 'components/feedback/ConfirmationDialog'
import { useIsWaitingToAddCard } from 'hooks/use-is-waiting-to-add-card'
import { useSnackbar } from 'notistack'
import ReactGA from 'react-ga4'
import { useHistory } from 'react-router-dom'

export type AddCardDialogActions =
  | 'BOOK'
  | 'EXTEND'
  | 'USE_NOW'
  | 'USE_SUBSCRIPTION'
  | 'RENEW_SUBSCRIPTION'
  | 'TASKPOD_ADMIN_CREATED_BUSINESS_ACCOUNT'

const getActionText = (action: AddCardDialogActions) => {
  switch (action) {
    case 'EXTEND':
      return 'extend the booking'
    case 'BOOK':
      return 'book the pod'
    case 'USE_NOW':
      return 'use the pod'
    case 'USE_SUBSCRIPTION':
      return 'use subscriptions'
    case 'RENEW_SUBSCRIPTION':
      return 'renew subscriptions'
  }
}

type Props = {
  open: boolean
  action: AddCardDialogActions
  businessMemberRole?: BusinessMemberRole
  isUsageStartOrBookingCreation?: boolean
  searchParams?: Record<string, string>
  onClose: () => void
  isBusinessSelected?: boolean
}

export const AddCardDialog = ({
  open,
  onClose,
  action,
  searchParams,
  businessMemberRole,
  isUsageStartOrBookingCreation,
  isBusinessSelected = false,
}: Props) => {
  const history = useHistory()
  const isWaitingToAddCard = useIsWaitingToAddCard()

  const { enqueueSnackbar } = useSnackbar()

  const isBusinessAdmin = businessMemberRole === 'OWNER'

  const isRegularUser = businessMemberRole === undefined

  const isBusinessSelectedByEmployee =
    businessMemberRole === 'EMPLOYEE' && isBusinessSelected

  const isPersonalSelectedByEmployee =
    businessMemberRole === 'EMPLOYEE' && isBusinessSelected === false

  const isTaskPodAdminCreatedBusinessAcc =
    action === 'TASKPOD_ADMIN_CREATED_BUSINESS_ACCOUNT'

  const actionText = getActionText(action)

  const search = new URLSearchParams({
    ...searchParams,
    prevPath: history.location.pathname,
  }).toString()

  return (
    <ConfirmationDialog
      variant="confirm"
      backText={
        isTaskPodAdminCreatedBusinessAcc
          ? 'Add a card later'
          : isBusinessSelectedByEmployee
          ? undefined
          : 'Cancel'
      }
      isOpen={open}
      onClose={reason => {
        ReactGA.event({
          category: 'Button press',
          action: 'Cancel (Pop up with the request to add credit card)',
        })
        if (isTaskPodAdminCreatedBusinessAcc && reason === 'closeBtnClick') {
          localStorage.setItem('businessAccountAddCardDialogShown', 'true')
          enqueueSnackbar(
            'No one from Business account will be able to use TaskPod with Business',
            {
              variant: 'error',
            },
          )
        }
        onClose()
      }}
      title={
        isTaskPodAdminCreatedBusinessAcc
          ? 'Add a credit card'
          : isRegularUser || isPersonalSelectedByEmployee
          ? "You haven't added a credit card yet"
          : `There was a problem with payment from ${
              isBusinessSelected ? 'business account’s' : 'your'
            } card`
      }
      text={
        isTaskPodAdminCreatedBusinessAcc
          ? 'Your business account was created. Add a credit card in order to access TaskPod with Business'
          : isBusinessSelectedByEmployee
          ? 'Contact your business account support to reach all details.'
          : isUsageStartOrBookingCreation
          ? `Please add a new card. Without payment information you will not be able to ${actionText}.`
          : `Please add a new card and complete payment. Until then, you will not be able to ${actionText}`
      }
      confirmText={
        isTaskPodAdminCreatedBusinessAcc
          ? 'Add a card now'
          : isBusinessSelectedByEmployee
          ? 'Got it'
          : 'Add a card '
      }
      onConfirm={() => {
        if (!isBusinessSelectedByEmployee) {
          ReactGA.event({
            category: 'Button press',
            action: 'Add a card (Pop up with the request to add credit card)',
          })
        }

        isWaitingToAddCard.setTrue()

        if (isTaskPodAdminCreatedBusinessAcc) {
          localStorage.setItem('businessAccountAddCardDialogShown', 'true')
        }

        if (isBusinessSelectedByEmployee === false) {
          history.push({
            pathname:
              (isBusinessAdmin && isBusinessSelected) ||
              isTaskPodAdminCreatedBusinessAcc
                ? routes.organizationCards
                : routes.profileCards,
            search,
          })
        }

        onClose()
      }}
    />
  )
}
