import { number, string, type, TypeOf } from 'io-ts'

import { TMyProfile } from '../user'

export const TSuccessfulAuthResult = type({
  accessToken: string,
  clientId: string,
  expires: number,
  userDetails: TMyProfile,
})

export type SuccessfulAuthResult = TypeOf<typeof TSuccessfulAuthResult>

export const TRefreshResult = type({
  accessToken: string,
  expires: number,
  userDetails: TMyProfile,
})
