import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconInstagram = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55556 4.22222C5.71461 4.22222 4.22222 5.71461 4.22222 7.55556V16.4444C4.22222 18.2854 5.71461 19.7778 7.55556 19.7778H16.4444C18.2854 19.7778 19.7778 18.2854 19.7778 16.4444V7.55556C19.7778 5.71461 18.2854 4.22222 16.4444 4.22222H7.55556ZM2 7.55556C2 4.48731 4.48731 2 7.55556 2H16.4444C19.5127 2 22 4.48731 22 7.55556V16.4444C22 19.5127 19.5127 22 16.4444 22H7.55556C4.48731 22 2 19.5127 2 16.4444V7.55556Z"
        fill="#215250"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9995 9.7774C10.7722 9.7774 9.77732 10.7723 9.77732 11.9996C9.77732 13.2269 10.7722 14.2218 11.9995 14.2218C13.2268 14.2218 14.2218 13.2269 14.2218 11.9996C14.2218 10.7723 13.2268 9.7774 11.9995 9.7774ZM7.5551 11.9996C7.5551 9.54502 9.54495 7.55518 11.9995 7.55518C14.4541 7.55518 16.444 9.54502 16.444 11.9996C16.444 14.4542 14.4541 16.4441 11.9995 16.4441C9.54495 16.4441 7.5551 14.4542 7.5551 11.9996Z"
        fill="#215250"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0009 5.8877C17.6146 5.8877 18.112 6.38516 18.112 6.99881V6.99992C18.112 7.61357 17.6146 8.11103 17.0009 8.11103C16.3873 8.11103 15.8898 7.61357 15.8898 6.99992V6.99881C15.8898 6.38516 16.3873 5.8877 17.0009 5.8877Z"
        fill="#215250"
      />
    </SvgIcon>
  )
}
