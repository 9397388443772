import { AccountType } from 'api/unions'
import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post } from 'lib/request'

import { T3dsPayment } from '../bookings'
import { TSubscriptionsList } from './subscription.codecs'

export type GetSubscriptionsListInput = {
  planType: AccountType
}

export const getSubscriptionsList = async ({
  planType,
}: GetSubscriptionsListInput) => {
  return pipe(
    await get('api/operation/subscriptions', {
      query: new URLSearchParams({ planType }),
    }),
    decodeJson(TSubscriptionsList),
  )
}

type CancelSubscriptionInput = {
  subscriptionId: UUID
}

export const cancelSubscription = async ({
  subscriptionId,
}: CancelSubscriptionInput) => {
  return pipe(
    await del('api/operation/users/me/subscriptions/:subscriptionId', {
      params: {
        subscriptionId,
      },
    }),
  )
}

export const cancelBusinessSubscription = async ({
  subscriptionId,
}: CancelSubscriptionInput) => {
  return pipe(
    await del('api/operation/users/me/business/subscriptions/:subscriptionId', {
      params: {
        subscriptionId,
      },
    }),
  )
}

export type GetChangeSubscriptionInput = {
  subscriptionId: UUID
}

export const changeSubscription = async ({
  subscriptionId,
}: GetChangeSubscriptionInput) => {
  return pipe(
    await post('api/operation/subscriptions/:subscriptionId/me/start', {
      params: {
        subscriptionId,
      },
    }),
    decodeJson(T3dsPayment),
  )
}

export const changeBusinessSubscription = async ({
  subscriptionId,
}: GetChangeSubscriptionInput) => {
  return pipe(
    await post(
      'api/operation/subscriptions/:subscriptionId/me/business/start',
      {
        params: {
          subscriptionId,
        },
      },
    ),
    decodeJson(T3dsPayment),
  )
}

export const renewSubscription = async () => {
  return pipe(
    await patch('api/operation/subscriptions/me/renewal', {}),
    decodeJson(T3dsPayment),
  )
}

export const renewBusinessSubscription = async () => {
  return pipe(
    await patch('api/operation/subscriptions/me/business/renewal', {}),
    decodeJson(T3dsPayment),
  )
}

export type GetChanllengeSubscriptionInput = {
  payload: string
}

export const challengeSubscription = async (
  input: GetChanllengeSubscriptionInput,
) => {
  return await post('api/operation/subscriptions/me/challenge', {
    body: input,
  })
}

type StartEnterpriseSubscriptionInput = {
  requestText: string
}

export const startEnterpriseSubscription = async ({
  requestText,
}: StartEnterpriseSubscriptionInput) => {
  return await post('api/operation/subscriptions/business/enterprise/request', {
    body: { requestText },
  })
}
