import { literal, TypeOf, union } from 'io-ts'

export const TUserRole = union([
  literal('USER'),
  literal('ADMIN'),
  literal('DRAFT_USER'),
])

export const TIdentType = union([
  literal('SETUP_INTENT'),
  literal('PAYMENT_INTENT'),
])

export const TCurrency = union([literal('USD'), literal('EUR'), literal('AUD')])

export type Currency = TypeOf<typeof TCurrency>

export const TStatus = union([
  literal('STARTED'),
  literal('FINISHED'),
  literal('FAILED'),
  literal('BOOKED'),
  literal('CANCELED'),
])

export type Status = TypeOf<typeof TStatus> // the same for rentals and bookings

export const TBoothStatus = union([
  literal('DRAFT'),
  literal('ACTIVE'),
  literal('INACTIVE'),
  literal('CLOSED'),
  literal('OCCUPIED'),
  literal('PUBLISHING'),
])

export type PodStatus = TypeOf<typeof TBoothStatus>

export const TImageSize = union([
  literal('WEB_IMAGE'),
  literal('MOBILE_IMAGE'),
  literal('THUMBNAIL'),
])

export const TBusinessAccountStatus = union([
  literal('ACTIVE'),
  literal('PENDING'),
  literal('INACTIVE'),
  literal('INITIAL'),
])
export type BusinessAccountStatus = TypeOf<typeof TBusinessAccountStatus>

export const businessAccountStatusMapping: Record<
  BusinessAccountStatus,
  string
> = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  INACTIVE: 'Inactive',
  INITIAL: 'Initial',
}

export const TAccountType = union([literal('PERSONAL'), literal('BUSINESS')])

export type AccountType = TypeOf<typeof TAccountType>

export const accountTypeMapping: Record<AccountType, string> = {
  PERSONAL: 'Personal',
  BUSINESS: 'Business',
}

export const TBusinessMemberRole = union([
  literal('EMPLOYEE'),
  literal('OWNER'),
])

export type BusinessMemberRole = TypeOf<typeof TBusinessMemberRole>

export type InviteBusinessMemberRole = 'EMPLOYEE' | 'OWNER'

export const businessMemberRoleMapping: Record<BusinessMemberRole, string> = {
  EMPLOYEE: 'Employee',
  OWNER: 'Admin',
}

export const TSourceType = union([literal('BUSINESS'), literal('USER')])

export type SourceType = TypeOf<typeof TSourceType>

export const TSubscriptionStatus = union([
  literal('ACTIVE'),
  literal('CANCELLING'),
  literal('CANCELED'),
  literal('PAID'),
  literal('FREEZE'),
  literal('PENDING'),
])

export const TDayOfWeek = union([
  literal('MONDAY'),
  literal('TUESDAY'),
  literal('WEDNESDAY'),
  literal('THURSDAY'),
  literal('FRIDAY'),
  literal('SATURDAY'),
  literal('SUNDAY'),
])

export type DayOfWeek = TypeOf<typeof TDayOfWeek>

export const TPodWorkingType = union([
  literal('ALL_DAY'),
  literal('SPECIFIC_HOURS'),
  literal('DAY_OFF'),
])
