import { BusinessAccountStatus, BusinessMemberRole, refreshToken } from 'api'
import { env } from 'app/env'
import constate from 'constate'
import { UUID } from 'io-ts-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'

export let globalAccessToken: string | null = null

export const useLogoutAcrossTabs = (callback: () => void) => {
  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === 'logout') {
        callback()
      }
    }

    window.addEventListener('storage', syncLogout)

    return () => {
      window.removeEventListener('storage', syncLogout)
    }
  }, [callback])

  const logout = useCallback(() => {
    callback()
    localStorage.setItem('logout', Date.now().toString())
  }, [callback])

  return logout
}

type AuthState =
  | { type: 'initial' }
  | {
      type: 'authenticated'
      accessToken: string
      scopes: Array<string>
      userId: UUID
      businessId?: UUID
      businessName?: string
      businessRole?: BusinessMemberRole
      status?: BusinessAccountStatus
    }
  | { type: 'unauthenticated' }

const useAuthHook = () => {
  const [auth, setAuth] = useState<AuthState>({
    type: 'initial',
  })

  const isAuthenticated = auth.type === 'authenticated'

  const isInitial = auth.type === 'initial'

  const $refresh = useMutation(refreshToken)

  const timeoutRef = useRef<number | null>(null)

  const setAuthData = useCallback(
    (
      accessToken: string,
      expiresInSeconds: number,
      scopes: Array<string>,
      userId: UUID,
      businessId?: UUID,
      businessName?: string,
      businessRole?: BusinessMemberRole,
      status?: BusinessAccountStatus,
    ) => {
      globalAccessToken = accessToken

      setAuth({
        type: 'authenticated',
        accessToken,
        scopes,
        userId,
        businessId,
        businessName,
        businessRole,
        status,
      })

      timeoutRef.current = window.setTimeout(() => {
        timeoutRef.current = null
        refresh()
      }, (expiresInSeconds - 60) * 1000)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const removeToken = useLogoutAcrossTabs(
    useCallback(() => {
      setAuth({ type: 'unauthenticated' })
      globalAccessToken = null

      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current)
      }

      localStorage.removeItem('USER_ACCEPTED_USAGE_TERMS')
      localStorage.removeItem('USER_ACCEPTED_BOOKING_TERMS')
    }, []),
  )

  const mutateRefresh = $refresh.mutate
  const resetRefresh = $refresh.reset

  const refresh = useCallback(() => {
    mutateRefresh(
      {
        clientId: env.REACT_APP_APPLICATION_ID,
      },
      {
        onSuccess: data => {
          const { userDetails, accessToken, expires } = data
          resetRefresh()
          setAuthData(
            accessToken,
            expires,
            userDetails.scopes,
            userDetails.userId,
            userDetails.businessId,
            userDetails.businessName,
            userDetails.businessRole,
            userDetails.status,
          )
        },
        onError: () => {
          removeToken()
        },
      },
    )
  }, [mutateRefresh, setAuthData, resetRefresh, removeToken])

  useEffect(() => {
    refresh()
  }, [refresh])

  return {
    isAuthenticated,
    isInitial,
    ...(auth.type === 'authenticated'
      ? {
          accessToken: auth.accessToken,
          userId: auth.userId,
          businessId: auth.businessId,
          businessName: auth.businessName,
          businessRole: auth.businessRole,
          businessStatus: auth.status,
        }
      : undefined),
    setAuthData,
    removeToken,
    refresh,
  } as const
}

export const [AuthProvider, useAuth] = constate(useAuthHook)
