import { allPageRoutes } from 'app/pages'
import { forwardRef, useEffect } from 'react'
import {
  Link,
  LinkProps,
  matchPath,
  NavLink,
  NavLinkProps,
} from 'react-router-dom'

import { LazyRoute } from './lazy-route'

const findComponentsForRoute = (path: string, routes: Array<LazyRoute>) => {
  return routes.filter(route =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    }),
  )
}

const preloadRouteComponent = (path: string) => {
  const components = findComponentsForRoute(path, allPageRoutes)

  for (const chunk of components) {
    chunk.component.preload()
  }
}

export const LinkPreload = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, ...rest }, ref) => {
    useEffect(() => {
      preloadRouteComponent(to)
    }, [to])

    return <Link ref={ref} to={to} {...rest} />
  },
)

export const NavLinkPreload = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ to, ...rest }, ref) => {
    useEffect(() => {
      preloadRouteComponent(to)
    }, [to])

    return <NavLink ref={ref} to={to} {...rest} />
  },
)
