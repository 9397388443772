import { Link, LinkProps } from '@mui/material'
import { LinkPreload, NavLinkPreload } from 'lib/lazy-routing'
import { forwardRef } from 'react'
import { LinkProps as RouterLinkProps, NavLinkProps } from 'react-router-dom'

export const LinkPrefetch = LinkPreload

export type InternalLinkProps = LinkProps & RouterLinkProps

export const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>(
  ({ to, ...rest }, ref) => {
    return <Link ref={ref} component={LinkPreload} to={to} {...rest} />
  },
)

export type InternalNavLinkProps = LinkProps & NavLinkProps

export const InternalNavLink = forwardRef<
  HTMLAnchorElement,
  InternalNavLinkProps
>(({ to, ...rest }, ref) => {
  return <Link ref={ref} component={NavLinkPreload} to={to} {...rest} />
})
