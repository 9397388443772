import {
  TBusinessAccount,
  TBusinessMember,
} from 'api/auth/organization/organization.codecs'
import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
} from 'api/list'
import { BusinessAccountStatus, InviteBusinessMemberRole } from 'api/unions'
import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, get, patch, post, put } from 'lib/request'

export const getOwnBusinessAccount = async () => {
  return pipe(
    await get('api/auth/business/me', {}),
    decodeJson(TBusinessAccount),
  )
}

export type UpdateBusinessAccountInput = {
  body: Record<string, string>
}

export const updateOwnBusinessAccount = async ({
  body,
}: UpdateBusinessAccountInput) => {
  return pipe(
    await put('api/auth/business/me', {
      body,
    }),
  )
}

export type GetStaffMembersInput = {
  pagination?: PaginationInput
  sort?: SortInput
  body: {
    statuses: Array<BusinessAccountStatus>
  }
}

export const getStaffMembers = async ({
  pagination,
  sort,
  body,
}: GetStaffMembersInput) => {
  const query = createListQueryParams({ pagination, sort })

  return pipe(
    await post('api/auth/business/users', {
      query,
      body,
    }),
    decodeJsonWithTotal(TBusinessMember),
  )
}

type ChangeMemberStatusInput = {
  businessAdminId: UUID
  status: 'ACTIVE' | 'DEACTIVATED'
  userIds: Array<UUID>
}

export const changeMemberStatus = async (body: ChangeMemberStatusInput) => {
  return pipe(
    await patch('api/operation/users/business/me', {
      body,
    }),
  )
}

type AddUserToBusinessAccountInput = {
  body: {
    firstName?: string
    lastName?: string
    email: string
    businessRoleName: InviteBusinessMemberRole
  }
}

export const addUserToBusinessAccount = async ({
  body,
}: AddUserToBusinessAccountInput) => {
  return pipe(
    await post('api/auth/business/me/users', {
      body,
    }),
  )
}

type AddDiscountToBusinessInput = {
  discountCode: string
}

export const addDiscountCodeToBusiness = async ({
  discountCode,
}: AddDiscountToBusinessInput) => {
  return pipe(
    await put('api/auth/business/me/discounts', {
      query: new URLSearchParams({
        discountCode,
      }),
    }),
    decodeJson(TBusinessAccount),
  )
}

type ResendInviteToBusinessInput = {
  userId: UUID
}

export const resendInviteToBusiness = async ({
  userId,
}: ResendInviteToBusinessInput) => {
  return pipe(
    await post('api/auth/business/me/users/:userId', {
      params: {
        userId,
      },
    }),
  )
}
