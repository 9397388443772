import { TSubscriptionName } from 'api/operation/subscription'
import { TCurrency, TSubscriptionStatus } from 'api/unions'
import {
  boolean,
  intersection,
  number,
  partial,
  string,
  type,
  TypeOf,
} from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

export const TMyBookingAndRentalExistence = type({
  userId: UUID,
  bookingExists: boolean,
  startedRentalExists: boolean,
  bookingExistsInFifteenMinutes: boolean,
})

export const TBookingAndRentalExistence = intersection([
  TMyBookingAndRentalExistence,
  type({
    email: string,
  }),
])

export type BookingAndRentalExistence = TypeOf<
  typeof TBookingAndRentalExistence
>

export const TMySubscription = intersection([
  type({
    subscriptionId: UUID,
    subscriptionName: TSubscriptionName,
    availableMinutes: number,
    userAvailableMinutes: number,
    status: TSubscriptionStatus,
    renewalDate: DateFromISOString,
    paidSubscriptionExists: boolean,
    subscriptionPricePerMonth: number,
    currency: TCurrency,
  }),
  partial({
    subscriptionPricePerQuarter: number,
    nextSubscriptionName: string,
    nextSubscriptionStartDate: DateFromISOString,
    membersQuantity: number,
    activeMembersQuantity: number,
  }),
])

export type MySubscription = TypeOf<typeof TMySubscription>

export const TPendingSubscriptionInfo = type({
  paymentMethodExists: boolean,
  pendingSubscriptionExists: boolean,
})
