import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconInitial = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12 20.994C16.9706 20.994 21 16.9673 21 12C21 7.03265 16.9706 3.00586 12 3.00586C7.02944 3.00586 3 7.03265 3 12C3 16.9673 7.02944 20.994 12 20.994Z"
      fill="#EB833D"
    />
  </SvgIcon>
)
