import { createSvgIcon } from '@mui/material'

export const IconApple = createSvgIcon(
  <g>
    <path
      d="M18.7098 19.5C17.8798 20.74 16.9998 21.95 15.6598 21.97C14.3198 22 13.8898 21.18 12.3698 21.18C10.8398 21.18 10.3698 21.95 9.09985 22C7.78985 22.05 6.79985 20.68 5.95985 19.47C4.24985 17 2.93985 12.45 4.69985 9.39C5.56985 7.87 7.12985 6.91 8.81985 6.88C10.0998 6.86 11.3198 7.75 12.1098 7.75C12.8898 7.75 14.3698 6.68 15.9198 6.84C16.5698 6.87 18.3898 7.1 19.5598 8.82C19.4698 8.88 17.3898 10.1 17.4098 12.63C17.4398 15.65 20.0598 16.66 20.0898 16.67C20.0598 16.74 19.6698 18.11 18.7098 19.5ZM12.9998 3.5C13.7298 2.67 14.9398 2.04 15.9398 2C16.0698 3.17 15.5998 4.35 14.8998 5.19C14.2098 6.04 13.0698 6.7 11.9498 6.61C11.7998 5.46 12.3598 4.26 12.9998 3.5Z"
      fill="currentColor"
    />
  </g>,
  'IconApple',
)
