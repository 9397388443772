import { TUserResponse } from 'api'
import { TFeedback } from 'api/operation/feedback'
import { TBoothHistory } from 'api/stuff/booth'
import { TAccountType, TCurrency, TStatus } from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  string,
  type,
  TypeOf,
} from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

export const TStartRental = partial({
  boothId: UUID,
  createdAt: DateFromISOString,
  currency: TCurrency,
  holdAmount: number,
  pricePerQuarter: number,
  rentalId: UUID,
  rentalStatus: TStatus,
  userId: UUID,
  clientSecret: string,
  payload: string,
  replyTo: string,
  pinCode: string,
  boothResponse: TBoothHistory,
  accountType: TAccountType,
})

export const TUsageHistoryItemActive = intersection([
  type({
    booked: boolean,
    boothResponse: TBoothHistory,
    createdAt: DateFromISOString,
    currency: TCurrency,
    paid: boolean,
    pricePerQuarter: number,
    rentalId: UUID,
    rentalStatus: TStatus,
    startedAt: DateFromISOString,
    userResponse: TUserResponse,
    accountType: TAccountType,
  }),
  partial({
    pinCode: string,
    bookedTo: DateFromISOString,
    holdAmount: number,
  }),
])

export const TUsageHistoryListItem = intersection([
  TUsageHistoryItemActive,
  type({
    finishedAt: DateFromISOString,
    totalPrice: number,
    usageTimeInMinutes: number,
  }),
  partial({
    invoiceUrl: string,
    feedbackResponse: TFeedback,
    finishedByAdmin: boolean,
  }),
])

export type UsageHistoryListItem = TypeOf<typeof TUsageHistoryListItem>

export const TUsageHistoryActive = array(TUsageHistoryItemActive)

export const TUsageHistoryList = array(TUsageHistoryListItem)
