import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconMinus = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M14.3333 10.333H10.3333L5.33334 10.333V14.333H10.3333H14.3333L19.3333 14.333V10.333L14.3333 10.333Z"
      fill="currentColor"
    />
  </SvgIcon>
)
