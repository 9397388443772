import { createSvgIcon } from '@mui/material'

export const IconEyeClose = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1134 17.3748L19.0795 20.341L20.6705 18.75L4.92051 3L3.32952 4.59099L5.97215 7.23362C4.05638 8.78486 3 10.5455 3 10.5455V12.7955C3 12.7955 6.375 18.4205 12 18.4205C13.5498 18.4205 14.9287 17.9935 16.1134 17.3748ZM13.4551 14.7166L8.95392 10.2154C8.74307 10.656 8.625 11.1495 8.625 11.6705C8.625 13.5345 10.136 15.0455 12 15.0455C12.521 15.0455 13.0145 14.9274 13.4551 14.7166Z"
      fill="#E5E5E5"
    />
    <path
      d="M21 12.7955C21 12.7955 20.6719 13.3424 20.0497 14.0952L10.9419 4.98743C11.2851 4.94381 11.6379 4.9205 12 4.9205C17.625 4.9205 21 10.5455 21 10.5455V12.7955Z"
      fill="#E5E5E5"
    />
  </g>,
  'IconEyeClose',
)
