import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
  Status,
} from 'api'
import { AccountType } from 'api/unions'
import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, get, post, put } from 'lib/request'

import {
  T3dsPayment,
  TAvailableHours,
  TUpcomingBooking,
  TUpcomingBookingItem,
} from './bookings.codecs'

export type GetCurrentUserBookingsInput = {
  status: Status
  pagination?: PaginationInput
  sort?: SortInput
  direction?: 'DESC' | 'ASC'
}

export const getCurrentUserBookings = async ({
  status,
  pagination,
  sort,
  direction,
}: GetCurrentUserBookingsInput) => {
  const query = createListQueryParams({ pagination, sort })
  query.set('status', status)

  if (direction) query.set('direction', direction)

  return pipe(
    await get('api/operation/me/bookings', { query }),
    decodeJsonWithTotal(TUpcomingBookingItem),
  )
}

type GetUserBookingsInput = {
  userId: UUID
  status: Status
  pagination?: PaginationInput
  sort?: SortInput
  direction?: 'DESC' | 'ASC'
}

export const getUserBookings = async ({
  userId,
  status,
  pagination,
  sort,
  direction,
}: GetUserBookingsInput) => {
  const query = createListQueryParams({ pagination, sort })
  query.set('status', status)

  if (direction) query.set('direction', direction)

  return pipe(
    await get('api/operation/users/:userId/bookings', {
      query,
      params: {
        userId,
      },
    }),
    decodeJsonWithTotal(TUpcomingBookingItem),
  )
}

export type GetBookingAvailableHours = {
  boothId: UUID
  dateFrom: Date
  dateTo: Date
}

export const getBookingAvailableHours = async (
  input: GetBookingAvailableHours,
) => {
  return pipe(
    await post('api/operation/available', {
      body: input,
    }),
    decodeJson(TAvailableHours),
  )
}

export type CreateBookingInput = {
  boothId: UUID
  dateFrom: Date
  dateTo: Date
  accountType: AccountType
}

export const createBooking = async (input: CreateBookingInput) => {
  return pipe(
    await post('api/operation/me/bookings', {
      body: input,
    }),
    decodeJson(TUpcomingBooking),
  )
}

export const extendBooking = async (input: {
  bookingId: UUID
  dateTo: Date
}) => {
  return pipe(
    await put('api/operation/me/bookings/:bookingId', {
      params: { bookingId: input.bookingId },
      body: { dateTo: input.dateTo.toISOString() },
    }),
    decodeJson(T3dsPayment),
  )
}

export const cancelBooking = async (input: { bookingId: UUID }) => {
  return await put('api/operation/me/bookings/:bookingId/cancel', {
    params: { bookingId: input.bookingId },
  })
}

export const challengeBooking = async (input: { payload: string }) => {
  return await post('api/operation/me/bookings/challenge', {
    body: input,
  })
}

export type GetBookingDetailsInput = { bookingId: UUID }

export const getBookingDetails = async ({
  bookingId,
}: GetBookingDetailsInput) => {
  return pipe(
    await get('api/operation/me/bookings/:bookingId', {
      params: { bookingId },
    }),
    decodeJson(TUpcomingBookingItem),
  )
}

type UnlockBookedPodInput = { bookingId: UUID }

export const unlockBookedPod = async ({ bookingId }: UnlockBookedPodInput) => {
  return pipe(
    await post('api/operation/me/bookings/:bookingId/unlock', {
      params: {
        bookingId,
      },
    }),
  )
}
