import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconMapMarker = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.75C5 6.02208 8.13401 3 12 3C15.866 3 19 6.02208 19 9.75C19 11.2204 18.5715 12.6611 17.7633 13.908L13.1667 21H10.8333L6.23666 13.908C5.42851 12.6611 5 11.2204 5 9.75ZM12 12C13.2887 12 14.3333 10.9926 14.3333 9.75C14.3333 8.50736 13.2887 7.5 12 7.5C10.7113 7.5 9.66667 8.50736 9.66667 9.75C9.66667 10.9926 10.7113 12 12 12Z"
      fill="#F1691A"
    />
  </SvgIcon>
)
