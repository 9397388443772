import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconArrowLeftAlt = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        d="M15.0004 2.91016L8.40039 12.5102L15.0004 21.5102"
        stroke="#333333"
        stroke-miterlimit="10"
      />
    </SvgIcon>
  )
}
