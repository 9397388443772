import {
  TBoothStatus,
  TCurrency,
  TDayOfWeek,
  TImageSize,
  TPodWorkingType,
} from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  record,
  string,
  type,
  TypeOf,
} from 'io-ts'
import { DateFromISOString, optionFromNullable, UUID } from 'io-ts-types'

const TPricingItem = type({
  createdAt: string,
  itemId: UUID,
  itemName: string,
  itemValue: number,
})

export const TPricing = type({
  createdAt: string,
  pricingId: string,
  pricingName: string,
  pricePerQuarter: number,
  totalPrice: number,
  currency: TCurrency,
  pricingItems: array(TPricingItem),
})

export const TFeature = type({
  featureName: string,
  featureId: UUID,
  createdAt: DateFromISOString,
})

export type Feature = TypeOf<typeof TFeature>

export const TFeatures = array(TFeature)

export const TLocationBooth = type({
  createdAt: DateFromISOString,
  locationId: string,
  locationName: string,
})

const TImageVariation = type({
  createdAt: DateFromISOString,
  fileKey: UUID,
  fileUrl: string,
  imageSize: TImageSize,
  originalFileName: string,
})

export const TImageVariationRecord = record(UUID, array(TImageVariation))

export type ImageVariation = TypeOf<typeof TImageVariation>
export type ImageVariationRecord = TypeOf<typeof TImageVariationRecord>

const TWorkingHoursItem = type({
  dayOfWeek: TDayOfWeek,
  type: TPodWorkingType,
  from: string,
  to: string,
})

const TDayOffItem = type({
  date: DateFromISOString,
  recurring: boolean,
})

const TPodSchedule = partial({
  daysOff: array(TDayOffItem),
  workingHours: array(TWorkingHoursItem),
})

export type PodSchedule = TypeOf<typeof TPodSchedule>

export const TPod = intersection([
  type({
    boothId: UUID,
    name: string,
    createdAt: DateFromISOString,
    imageVariations: array(TImageVariationRecord),
    latitude: number,
    location: TLocationBooth,
    longitude: number,
    persons: number,
    status: TBoothStatus,
    pricing: TPricing,
    features: optionFromNullable(TFeatures),
    timeZone: string,
  }),
  partial({
    previewImage: type({
      fileKey: string,
      fileUrl: string,
      originalName: string,
    }),
    city: string,
    street: string,
    number: string,
    description: string,
    addressDescription: string,
    scheduleResponse: TPodSchedule,
  }),
])

export type Pod = TypeOf<typeof TPod>

export const TPodList = array(TPod)

export const TPricingShort = type({
  pricingId: UUID,
  currency: TCurrency,
  totalPrice: number,
})

const TPinCode = type({
  pinCodeId: UUID,
  pinCodeNumber: number,
  password: string,
  startDate: DateFromISOString,
  endDate: DateFromISOString,
})

export const TBoothHistory = intersection([
  type({
    boothId: UUID,
    occupied: boolean,
    name: string,
    status: TBoothStatus,
    pinCodes: array(TPinCode),
    timeZone: string,
  }),
  partial({
    persons: number,
    city: string,
    number: string,
    street: string,
    location: TLocationBooth,
    addressDescription: string,
    imageVariations: array(TImageVariationRecord),
    previewImage: type({
      fileKey: string,
      fileUrl: string,
      originalName: string,
    }),
    pricing: TPricingShort,
    wifiNetwork: string,
    wifiPassword: string,
  }),
])

export const TPodBasicInfo = intersection([
  type({
    boothId: UUID,
    boothName: string,
  }),
  partial({
    locationName: string,
  }),
])

export const TPodListBasicInfo = array(TPodBasicInfo)
