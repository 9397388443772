import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconPrice = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM5.79539 4.5123C6.14925 4.27641 6.56215 4.10395 7.006 4.00097C7.06083 3.50051 7.48494 3.11111 8 3.11111C8.51521 3.11111 8.93942 3.50073 8.99405 4.0014C9.69724 4.16454 10.3363 4.50383 10.7767 5.01121C11.0776 5.35793 11.1038 5.86502 10.8401 6.24089C10.5765 6.61676 10.0908 6.76483 9.66234 6.59997L9 6.34512V7.11348C9.44156 7.21661 9.85234 7.38859 10.2046 7.62341C10.8058 8.02415 11.3333 8.68756 11.3333 9.55556C11.3333 10.4236 10.8058 11.087 10.2046 11.4877C9.85075 11.7236 9.43785 11.8961 8.994 11.999C8.93917 12.4995 8.51506 12.8889 8 12.8889C7.48479 12.8889 7.06058 12.4993 7.00595 11.9986C6.30276 11.8355 5.66367 11.4962 5.22332 10.9888C4.9224 10.6421 4.89623 10.135 5.15986 9.75911C5.42348 9.38325 5.90918 9.23517 6.33765 9.40003L7 9.65488V8.88652C6.55843 8.78339 6.14766 8.61142 5.79539 8.37659C5.1942 7.97585 4.66667 7.31244 4.66667 6.44444C4.66667 5.57645 5.1942 4.91304 5.79539 4.5123ZM9 9.88105V9.23006C9.034 9.24866 9.0658 9.2679 9.0953 9.28757C9.21142 9.36497 9.27488 9.43723 9.30555 9.48558C9.33334 9.52939 9.33333 9.55052 9.33333 9.55547V9.55556V9.55564C9.33333 9.56059 9.33334 9.58172 9.30555 9.62553C9.27488 9.67389 9.21142 9.74614 9.0953 9.82355C9.0658 9.84321 9.034 9.86245 9 9.88105ZM6.9047 6.17646C6.9342 6.15679 6.966 6.13755 7 6.11895V6.76994C6.966 6.75134 6.9342 6.7321 6.9047 6.71243C6.78858 6.63503 6.72512 6.56278 6.69445 6.51442C6.66666 6.47061 6.66667 6.44948 6.66667 6.44453V6.44444V6.44436C6.66667 6.43941 6.66666 6.41828 6.69445 6.37447C6.72512 6.32611 6.78858 6.25386 6.9047 6.17646Z"
      fill="#F1691A"
    />
  </SvgIcon>
)
