import {
  AccountType,
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
  Status,
} from 'api'
import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, get, post, put } from 'lib/request'

import {
  TStartRental,
  TUsageHistoryActive,
  TUsageHistoryListItem,
} from './rentals.codecs'

export type GetMyRentalsInput = {
  status: Status
  pagination?: PaginationInput
}

export const getMyRentals = async ({
  status,
  pagination,
}: GetMyRentalsInput) => {
  const query = createListQueryParams({ pagination })
  query.set('status', status)

  return pipe(
    await get('api/operation/me/rentals', { query }),
    decodeJson(TUsageHistoryActive),
  )
}

export type GetUserRentalsInput = {
  userId: UUID
  status: Status
}

export const getUserRentals = async ({
  userId,
  status,
}: GetUserRentalsInput) => {
  return pipe(
    await get('api/operation/users/:userId/rentals', {
      params: {
        userId,
      },
      query: new URLSearchParams({
        status,
      }),
    }),
    decodeJson(TUsageHistoryActive),
  )
}

export type GetUserFinishedRentals = PaginationInput

export type GetUserRentalInput = {
  rentalId: UUID
}

export const getUserRental = async (input: GetUserRentalInput) => {
  return pipe(
    await get('api/operation/me/rentals/:rentalId', {
      params: { rentalId: input.rentalId },
    }),
    decodeJson(TUsageHistoryListItem),
  )
}

export const getUserFinishedRentals = async (input: GetUserFinishedRentals) => {
  const query = createListQueryParams({ pagination: input })
  query.set('status', 'FINISHED')

  return pipe(
    await get('api/operation/me/rentals', { query }),
    decodeJsonWithTotal(TUsageHistoryListItem),
  )
}

export type GetBusinessUsageHistoryInput = {
  pagination: PaginationInput
  sort?: SortInput
}

export const getBusinessUsageHistory = async ({
  pagination,
  sort,
}: GetBusinessUsageHistoryInput) => {
  const query = createListQueryParams({
    pagination: pagination,
    sort: sort,
  })

  return pipe(
    await get('api/operation/businesses/me/rentals/history', { query }),
    decodeJsonWithTotal(TUsageHistoryListItem),
  )
}

export const startBoothRental = async (input: {
  boothId: UUID
  expectedEndOfUsage: Date
  accountType: AccountType
}) => {
  return pipe(
    await post('api/operation/me/rentals', {
      body: input,
    }),
    decodeJson(TStartRental),
  )
}

export const challengeBoothRental = async (input: { payload: string }) => {
  return await post('api/operation/me/rentals/challenge', {
    body: input,
  })
}

export const finishBoothRental = async (input: { rentalId: UUID }) => {
  return pipe(
    await put('api/operation/me/rentals/:rentalId', {
      params: {
        rentalId: input.rentalId,
      },
    }),
    decodeJson(TStartRental),
  )
}

type UnlockPodInput = { rentalId: UUID }

export const unlockPod = async ({ rentalId }: UnlockPodInput) => {
  return pipe(
    await post('api/operation/me/rentals/:rentalId/unlock', {
      params: {
        rentalId,
      },
    }),
  )
}
