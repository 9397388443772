import { TCurrency } from 'api/unions'
import { TAccountType } from 'api/unions'
import {
  array,
  boolean,
  intersection,
  literal,
  number,
  partial,
  type,
  TypeOf,
  union,
} from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

export const TSubscriptionName = union([
  literal('Lite'),
  literal('Standard'),
  literal('Pro'),
  literal('Small'),
  literal('Medium'),
  literal('Large'),
  literal('Enterprise'),
])

export const TSubscription = intersection([
  type({
    subscriptionName: TSubscriptionName,
    subscriptionId: UUID,
    availableMinutes: number,
    currency: TCurrency,
  }),
  partial({
    planType: TAccountType,
    pricePerMonth: number,
    createdAt: DateFromISOString,
    pricePerQuarter: number,
    updatedAt: DateFromISOString,
    currentUsersSubscription: boolean,
    membersQuantity: number,
  }),
])

export type Subscription = TypeOf<typeof TSubscription>

export const TSubscriptionsList = array(TSubscription)
