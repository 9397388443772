import { createSvgIcon } from '@mui/material'

export const IconArrowLeft = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77653 8.16199L11.9441 12.3296L9.27372 15L4 9.72628V6.27372L9.27372 1L11.9441 3.67041L7.77653 7.83801V8.16199Z"
      fill="#8B8B8B"
    />
  </g>,
  'IconArrowLeft',
)
