import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconFundsSpent = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M1.5 20.312V15.2765C1.5 15.176 1.53446 15.0784 1.59762 15.0002C1.68111 14.8968 1.80687 14.8367 1.93975 14.8367H4.65745H4.8786C5.41824 14.8367 5.95605 14.774 6.4812 14.6497L8.27199 14.2261C9.10821 14.0282 9.97383 13.9869 10.8251 14.1043L14.8224 14.6552C15.6923 14.7752 16.4726 14.798 16.8813 15.5752C17.5065 16.7639 15.4274 17.6339 14.0902 17.7601L11.4248 18.0116C11.3556 18.0181 11.2917 18.0515 11.247 18.1048C11.1364 18.2362 11.1782 18.4366 11.3321 18.5128L11.9433 18.8155C12.0957 18.8911 12.2683 18.916 12.436 18.8869L16.6503 18.1528C17.145 18.0665 17.6134 17.8685 18.0198 17.5736L19.1265 16.771C20.0954 16.0682 21.3966 15.3322 22.1628 16.2515C22.6868 16.8799 21.9102 17.851 21.3036 18.3997L19.4632 20.0644C18.6789 20.7739 17.7117 21.25 16.671 21.4387L11.8999 22.3042C10.7271 22.5169 9.51937 22.4249 8.39235 22.0372L5.37526 20.9987C4.90002 20.8352 4.40093 20.7518 3.89832 20.7518H1.93975C1.80687 20.7518 1.68111 20.6917 1.59762 20.5883C1.53446 20.51 1.5 20.4125 1.5 20.312Z"
      fill="#EB833D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4383 12.1814C15.8022 12.1814 18.5291 9.45448 18.5291 6.09069C18.5291 2.72689 15.8022 0 12.4383 0C9.07455 0 6.34766 2.72689 6.34766 6.09069C6.34766 9.45448 9.07455 12.1814 12.4383 12.1814ZM10.5201 3.05604C10.828 2.8508 11.1873 2.70073 11.5735 2.61114C11.6212 2.17569 11.9902 1.83687 12.4383 1.83687C12.8866 1.83687 13.2557 2.17588 13.3033 2.61152C13.9151 2.75346 14.4712 3.04868 14.8543 3.49014C15.1161 3.79184 15.1389 4.23305 14.9095 4.56009C14.6802 4.88714 14.2576 5.01597 13.8847 4.87252L13.3084 4.65078V5.31933C13.6926 5.40906 14.0501 5.5587 14.3566 5.76301C14.8797 6.11169 15.3387 6.68894 15.3387 7.44417C15.3387 8.19942 14.8797 8.77665 14.3566 9.12533C14.0487 9.33057 13.6894 9.48063 13.3032 9.57022C13.2555 10.0057 12.8865 10.3445 12.4383 10.3445C11.9901 10.3445 11.621 10.0055 11.5734 9.56985C10.9616 9.42791 10.4055 9.13269 10.0224 8.69123C9.76053 8.38954 9.73776 7.94832 9.96714 7.62129C10.1965 7.29425 10.6191 7.16541 10.9919 7.30886L11.5682 7.5306V6.86205C11.184 6.77231 10.8266 6.62268 10.5201 6.41837C9.99702 6.06968 9.53801 5.49244 9.53801 4.7372C9.53801 3.98196 9.99702 3.40473 10.5201 3.05604ZM13.3084 7.72738V7.16096C13.338 7.17714 13.3657 7.19388 13.3914 7.211C13.4924 7.27835 13.5476 7.34121 13.5743 7.38329C13.5985 7.4214 13.5985 7.43979 13.5985 7.44409C13.5985 7.4484 13.5985 7.46694 13.5743 7.50505C13.5476 7.54713 13.4924 7.60999 13.3914 7.67734C13.3657 7.69446 13.338 7.7112 13.3084 7.72738ZM11.4853 4.50402C11.511 4.48691 11.5387 4.47018 11.5682 4.45398V5.02041C11.5387 5.00423 11.511 4.98748 11.4853 4.97039C11.3843 4.90304 11.3291 4.84016 11.3024 4.79808C11.2782 4.75996 11.2782 4.74158 11.2782 4.73729C11.2782 4.73298 11.2782 4.71444 11.3024 4.67632C11.3291 4.63423 11.3843 4.57137 11.4853 4.50402Z"
      fill="#EB833D"
    />
  </SvgIcon>
)
