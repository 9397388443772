import { TBusinessAccountStatus, TBusinessMemberRole } from 'api/unions'
import { string, type, TypeOf } from 'io-ts'
import { optionFromNullable, UUID } from 'io-ts-types'

export const TBusinessAccount = type({
  businessId: UUID,
  businessName: string,
  businessEmail: string,
  firstName: optionFromNullable(string),
  lastName: optionFromNullable(string),
  address: optionFromNullable(string),
  phoneNumber: optionFromNullable(string),
  status: TBusinessAccountStatus,
})

export type BusinessAccount = TypeOf<typeof TBusinessAccount>

export const TBusinessMember = type({
  userId: UUID,
  email: string,
  firstName: optionFromNullable(string),
  lastName: optionFromNullable(string),
  businessRoleName: TBusinessMemberRole,
  businessStatus: TBusinessAccountStatus,
})

export type BusinessMember = TypeOf<typeof TBusinessMember>
