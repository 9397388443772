import { useStripe } from '@stripe/react-stripe-js'
import {
  challengeSubscription,
  getPendingSubscriptionInfo,
  qk,
  renewBusinessSubscription,
} from 'api'
import { AddCardDialog } from 'components/app/AddCardDialog'
import constate from 'constate'
import { useAuth } from 'hooks/use-auth'
import { useBoolean } from 'hooks/use-boolean'
import { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useLocation } from 'react-router'

export const usePendingBusinessSubscriptionHook = () => {
  const stripe = useStripe()

  const { businessRole } = useAuth()

  const location = useLocation()

  const { data: pendingBusinessSubscription, refetch } = useQuery(
    qk.operation.users.pendingBusinessSubscription.toKey(),
    getPendingSubscriptionInfo,
    {
      enabled: businessRole === 'OWNER',
    },
  )

  const { mutate: payPendingBusinessSubscription } = useMutation(
    renewBusinessSubscription,
  )

  const { mutate: confirmSubscriptionPayment } = useMutation(
    challengeSubscription,
  )

  const {
    setTrue: openAddCardDialog,
    isTrue: isAddCardDialogOpen,
    setFalse: closeAddCardDialog,
  } = useBoolean()

  const isPendingBusinessSubscription =
    pendingBusinessSubscription !== undefined
      ? pendingBusinessSubscription.pendingSubscriptionExists
      : undefined

  useEffect(() => {
    if (
      pendingBusinessSubscription !== undefined &&
      pendingBusinessSubscription.paymentMethodExists === false &&
      pendingBusinessSubscription.pendingSubscriptionExists === true &&
      localStorage.getItem('businessAccountAddCardDialogShown') === null
    ) {
      openAddCardDialog()
    }
  }, [pendingBusinessSubscription, location.pathname, openAddCardDialog])

  useEffect(() => {
    if (
      pendingBusinessSubscription !== undefined &&
      pendingBusinessSubscription.paymentMethodExists === true &&
      pendingBusinessSubscription.pendingSubscriptionExists === true
    ) {
      payPendingBusinessSubscription(undefined, {
        onSuccess: async data => {
          if (stripe && data.clientSecret) {
            const { paymentIntent } = await stripe.confirmCardPayment(
              data.clientSecret,
            )
            if (paymentIntent && data.payload) {
              confirmSubscriptionPayment(
                {
                  payload: data.payload,
                },
                {
                  onSuccess: () => refetch(),
                },
              )
            }
          } else {
            refetch()
          }
        },
      })
    }
  }, [
    confirmSubscriptionPayment,
    payPendingBusinessSubscription,
    pendingBusinessSubscription,
    refetch,
    stripe,
  ])

  return {
    isPendingBusinessSubscription,
    refetchPendingBusinessSubscription: refetch,
    PendingSubscriptionAddCardDialog: () => (
      <AddCardDialog
        open={isAddCardDialogOpen}
        onClose={closeAddCardDialog}
        action="TASKPOD_ADMIN_CREATED_BUSINESS_ACCOUNT"
      />
    ),
  }
}

export const [
  PendingBusinessSubscriptionProvider,
  usePendingBusinessSubscription,
] = constate(usePendingBusinessSubscriptionHook)
