import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCreditCardSelected = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM17.2955 9.4205L15.7045 7.8295L10.409 13.125H10.216L8.2955 11.2045L6.7045 12.7955L9.28401 15.375H11.341L17.2955 9.4205Z"
        fill="#4CAAA4"
      />
    </SvgIcon>
  )
}
