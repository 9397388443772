import constate from 'constate'
import { useBoolean } from 'hooks/use-boolean'

const usePincodeDialogHook = () => {
  const open = useBoolean()

  return {
    openPincodeDialog: open.setTrue,
    isPincodeDialogOpen: open.isTrue,
    closePincodeDialog: open.setFalse,
  }
}

export const [PincodeDialogProvider, usePincodeDialog] =
  constate(usePincodeDialogHook)
