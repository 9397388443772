import { useTheme } from '@mui/material'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCheckMark = ({
  search,
  ...props
}: SvgIconProps & { search?: boolean }) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4.72579L6.89388 13H4.38546L1 9.54434L3.67041 6.81855L5.63967 8.82865L12.3296 2L15 4.72579Z"
        fill={props.fill || theme.palette.primary.main}
      />
    </SvgIcon>
  )
}
