import 'styles/index.css'

import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { env } from 'app/env'
import { reportWebVitals } from 'app/report-web-vitals'
import { theme } from 'app/theme'
import { SnackbarProvider } from 'components/feedback/Snackbar'
import { ScrollToTop } from 'components/utils/ScrollToTop'
import { ActiveSessionSnackbarProvider } from 'hooks/use-active-session-snackbar'
import { AuthProvider } from 'hooks/use-auth'
import { IsWaitingToAddCardProvider } from 'hooks/use-is-waiting-to-add-card'
import { HeaderColorProvider } from 'hooks/use-landing-header-change'
import { PendingBusinessSubscriptionProvider } from 'hooks/use-pending-business-subscription'
import { PincodeDialogProvider } from 'hooks/use-pincode-dialog'
import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'

import { App } from './app'

const stripePromise = loadStripe(env.REACT_APP_STRIPE_SECRET)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ErrorBoundary fallbackRender={() => <div>Runtime error.</div>}>
          <Suspense fallback={null}>
            <BrowserRouter>
              <ScrollToTop>
                <SnackbarProvider>
                  <GoogleOAuthProvider
                    clientId={env.REACT_APP_APPLICATION_GOOGLE_ID}
                  >
                    <AuthProvider>
                      <PincodeDialogProvider>
                        <ActiveSessionSnackbarProvider>
                          <HeaderColorProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Elements stripe={stripePromise}>
                                <PendingBusinessSubscriptionProvider>
                                  <IsWaitingToAddCardProvider>
                                    <App />
                                  </IsWaitingToAddCardProvider>
                                </PendingBusinessSubscriptionProvider>
                              </Elements>
                            </LocalizationProvider>
                          </HeaderColorProvider>
                        </ActiveSessionSnackbarProvider>
                      </PincodeDialogProvider>
                    </AuthProvider>
                  </GoogleOAuthProvider>
                </SnackbarProvider>
              </ScrollToTop>
            </BrowserRouter>
          </Suspense>
        </ErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
)

if (
  process.env.NODE_ENV === 'development' &&
  (process.env.REACT_APP_MSW === 'on' || process.env.REACT_APP_MSW === 'quiet')
) {
  const { worker } = require('mocks/browser')
  worker.start({ quiet: process.env.REACT_APP_MSW === 'quiet' })
}

if (env.REACT_APP_REPORT_WEB_VITALS === 'on') {
  reportWebVitals(console.log)
}
