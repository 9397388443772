import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconCreditCard = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.1667 5H3V8.40625H21.1667V5Z" fill="#203434" />
      <path d="M21.1667 10.6771H3V18.625H21.1667V10.6771Z" fill="#203434" />
    </SvgIcon>
  )
}
