import { createLazyRoute, LazyRoute } from 'lib/lazy-routing'

import { routes } from './routes'

const lazyRoute = createLazyRoute({
  modifyTitle: title => `${title} · TaskPod`,
})

export const allAccessRoutes: Array<LazyRoute> = [
  lazyRoute({
    title: 'TaskPod',
    path: routes.index,
    factory: () => import('pages/landing'),
  }),
  lazyRoute({
    title: 'Pods',
    path: [routes.pods, routes.podsMap, routes.podsList],
    factory: () => import('pages/pods'),
  }),
  lazyRoute({
    title: 'Pod details',
    path: routes.podDetails,
    factory: () => import('pages/pods/details'),
  }),
  lazyRoute({
    title: 'Help',
    path: routes.help,
    factory: () => import('pages/help'),
  }),
  lazyRoute({
    title: 'About',
    path: routes.about,
    factory: () => import('pages/about'),
  }),
  lazyRoute({
    title: 'Privacy Policy',
    path: routes.privacyPolicy,
    factory: () => import('pages/privacy-policy'),
  }),
]

export const podsRoutes: Array<LazyRoute> = [
  lazyRoute({
    title: 'Pods on the map',
    path: routes.podsMap,
    factory: () => import('pages/pods/main/mobile/map'),
  }),
  lazyRoute({
    title: 'Pods list',
    path: routes.podsList,
    factory: () => import('pages/pods/main/mobile/list'),
  }),
]

export const pageRoutesAuth: Array<LazyRoute> = [
  lazyRoute({
    title: 'My profile',
    path: [
      routes.myProfile,
      routes.profileCards,
      routes.profileHistory,
      routes.profileSecurity,
      routes.profileSubscription,
      routes.accountCredits,
      routes.cancelledPersonalSubscription,
    ],
    factory: () => import('pages/profile'),
  }),
  lazyRoute({
    title: 'Organization info',
    path: [
      routes.organizationInfo,
      routes.organizationStaffAccess,
      routes.organizationCards,
      routes.inviteStaffMember,
      routes.organizationSubscription,
      routes.thankYouForContacting,
      routes.organizationUsageHistory,
      routes.cancelledOrganizationSubscription,
    ],
    factory: () => import('pages/organization'),
  }),
  lazyRoute({
    title: 'Usage details',
    path: routes.rentalDetails,
    factory: () => import(`components/app/UsageHistory/details`),
  }),
  lazyRoute({
    title: 'Pod in use',
    path: routes.podUsage,
    factory: () => import('pages/pods/usage'),
  }),
  lazyRoute({
    title: 'Book a pod',
    path: routes.bookAPod,
    factory: () => import('pages/pods/book'),
  }),
  lazyRoute({
    title: 'Leave feedback',
    path: routes.leaveFeedback,
    factory: () => import('pages/pods/feedback'),
  }),
  lazyRoute({
    title: 'Bookings',
    path: routes.bookings,
    factory: () => import('pages/bookings'),
  }),
  lazyRoute({
    title: 'Booking details',
    path: routes.bookingDetails,
    factory: () => import('pages/bookings/details'),
  }),
]

export const pageRoutesUnauth: Array<LazyRoute> = [
  lazyRoute({
    title: 'Sign in',
    path: routes.signIn,
    factory: () => import('pages/entry'),
  }),
  lazyRoute({
    title: 'Forgot password',
    path: routes.forgotPassword,
    factory: () => import('pages/password/forgot'),
  }),
  lazyRoute({
    title: 'Confirm password recovery',
    path: routes.recoveryPassword,
    factory: () => import('pages/password/recovery'),
  }),
  lazyRoute({
    title: 'Confirm password recovery',
    path: routes.createPassword,
    factory: () => import('pages/password/new'),
  }),
  lazyRoute({
    title: 'Email confirmation',
    path: routes.emailConfirmation,
    factory: () => import('pages/email'),
  }),
  lazyRoute({
    title: 'Email verification resend',
    path: routes.emailResend,
    factory: () => import('pages/email/resend'),
  }),
  lazyRoute({
    title: 'Sign up',
    path: routes.signUp,
    factory: () => import('pages/entry'),
  }),
  lazyRoute({
    title: 'Email sent',
    path: routes.emailSent,
    factory: () => import('pages/email-sent'),
  }),
]

export const allPageRoutes = [
  ...allAccessRoutes,
  ...pageRoutesAuth,
  ...pageRoutesUnauth,
  ...podsRoutes,
]
