import { env } from 'app/env'
import { useAuth } from 'hooks/use-auth'
import { lazy } from 'react'
import ReactGA from 'react-ga4'

const LazyAuth = lazy(() => import('pages/auth'))
const LazyUnauth = lazy(() => import('pages/unauth'))

const TRACKING_ID = 'G-KZ4RYS28V7'

if (window.location.hostname === env.REACT_APP_APPLICATION_PROD_HOST_NAME) {
  ReactGA.initialize(TRACKING_ID)
}

export const App = () => {
  const { isAuthenticated, isInitial } = useAuth()

  if (isInitial) return null

  if (!isAuthenticated) {
    return <LazyUnauth />
  }

  if (isAuthenticated) {
    return <LazyAuth />
  }

  return null
}
