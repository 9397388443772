import { Box, IconButton, Typography } from '@mui/material'
import { routes } from 'app/routes'
import { IconCross } from 'assets/icons'
import { Button } from 'components/basic/Button'
import { InternalLink } from 'components/navigation/InternalLink'
import constate from 'constate'
import { useAuth } from 'hooks/use-auth'
import { useBoolean } from 'hooks/use-boolean'
import { usePincodeDialog } from 'hooks/use-pincode-dialog'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

const KEY = 'activeSessionSnackbarKey'

const useActiveSessionSnackbarHook = () => {
  const open = useBoolean(false)

  const { isAuthenticated } = useAuth()

  const { isPincodeDialogOpen } = usePincodeDialog()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const wasClosed = useBoolean(false)

  const location = useLocation()

  const isUsagePage = useRouteMatch(routes.podUsage)

  const active = useBoolean(false)

  useEffect(() => {
    if (!isAuthenticated) {
      closeSnackbar(KEY)
      open.setFalse()
      active.setFalse()
    }
  }, [active, closeSnackbar, isAuthenticated, open])

  useEffect(() => {
    //TODO: Find better solution
    wasClosed.setFalse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (isUsagePage) {
      closeSnackbar(KEY)
      open.setFalse()
    } else if (
      active.isTrue &&
      open.isFalse &&
      wasClosed.isFalse &&
      isAuthenticated &&
      isPincodeDialogOpen === false
    ) {
      open.setTrue()
      enqueueSnackbar(null, {
        autoHideDuration: null,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        key: KEY,
        content: _key => (
          <Box
            width="300px"
            p={2}
            bgcolor="#FFF"
            borderRadius="2px"
            boxShadow="0px 8px 12px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.08)"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
              width="100%"
            >
              <Typography fontWeight="600">
                You have an active session!
              </Typography>
              <IconButton
                onClick={() => {
                  closeSnackbar(KEY)
                  open.setFalse()
                  wasClosed.setTrue()
                }}
              >
                <IconCross sx={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
            <Typography variant="caption">
              To view your usage details or access your pin code click below
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={InternalLink}
              to={routes.podUsage}
              sx={{ width: '100%', mt: 1.5 }}
              onClick={() => {
                closeSnackbar(KEY)
                open.setFalse()
                wasClosed.setTrue()
              }}
            >
              Usage
            </Button>
          </Box>
        ),
      })
    }
  }, [
    active.isTrue,
    closeSnackbar,
    enqueueSnackbar,
    isAuthenticated,
    isPincodeDialogOpen,
    isUsagePage,
    open,
    wasClosed,
  ])

  return {
    activateSessionSnackbar: active.setTrue,
    deactivateSessionSnackbar: active.setFalse,
  }
}

export const [ActiveSessionSnackbarProvider, useActiveSessionSnackbar] =
  constate(useActiveSessionSnackbarHook)
