import { createSvgIcon } from '@mui/material'

export const IconArrowRight = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16683 8.16199L3.99923 12.3296L6.66964 15L11.9434 9.72628V6.27372L6.66964 1L3.99923 3.67041L8.16683 7.83801V8.16199Z"
      fill="#8B8B8B"
    />
  </g>,
  'IconArrowRight',
)
