import { createSvgIcon } from '@mui/material'

export const IconSearch = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3125 19.625C13.1796 19.625 14.903 19.0094 16.2907 17.9701L19.9728 21.6522L21.6522 19.9728L17.9701 16.2907C19.0094 14.903 19.625 13.1796 19.625 11.3125C19.625 6.72163 15.9034 3 11.3125 3C6.72163 3 3 6.72163 3 11.3125C3 15.9034 6.72163 19.625 11.3125 19.625ZM11.3125 17.25C14.5917 17.25 17.25 14.5917 17.25 11.3125C17.25 8.03331 14.5917 5.375 11.3125 5.375C8.03331 5.375 5.375 8.03331 5.375 11.3125C5.375 14.5917 8.03331 17.25 11.3125 17.25Z"
      fill="#4A4A4A"
    />
  </g>,
  'IconSearch',
)
