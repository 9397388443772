import { routes } from 'app/routes'
import constate from 'constate'
import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'

export type HeaderColor = 'transparent' | 'background.default'

const useHeaderColorChange = () => {
  const [headerColor, setHeaderColor] =
    useState<HeaderColor>('background.default')

  const isLandingPage = Boolean(useRouteMatch(routes.index)?.isExact)

  const setWhiteHeader = () => setHeaderColor('background.default')

  const setTransparentHeader = () => setHeaderColor('transparent')

  useEffect(() => {
    if (isLandingPage) {
      setTransparentHeader()
    } else {
      setWhiteHeader()
    }
  }, [isLandingPage])

  return {
    headerColor,
    setWhiteHeader,
    setTransparentHeader,
  }
}

export const [HeaderColorProvider, useHeaderColorChangeContext] =
  constate(useHeaderColorChange)
