import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router'

type Props = {
  children: ReactNode
}

export const ScrollToTop = ({ children }: Props) => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <>{children}</>
}
