import { GetStaffMembersInput, ValidateConfirmRequestInput } from 'api/auth'
import {
  GetBookingAvailableHours,
  GetBusinessUsageHistoryInput,
  GetMySubscriptionInput,
} from 'api/operation'
import { buildModuleCacheKey } from 'lib/request'

import { GetUserSourceInput } from './finance'
import {
  GetBookingDetailsInput,
  GetCurrentUserBookingsInput,
  GetMyRentalsInput,
  GetSubscriptionsListInput,
  GetUserFinishedRentals,
  GetUserRentalInput,
} from './operation'
import {
  GetBoothBasicInfo,
  GetBoothDetailsInput,
  GetBoothsListByGeoInput,
  GetBoothsListInput,
} from './stuff'
//`qk` stands for "query keys"

export const qk = buildModuleCacheKey({
  auth: {
    user: {
      me: null,
      myBookingsAndRental: null,
    },
    business: {
      me: null,
      accountList: (input: GetStaffMembersInput) => [input],
      accountListAll: null,
    },
    confirmation: {
      validateConfirm: (input: ValidateConfirmRequestInput) => [input],
    },
  },
  finance: {
    source: {
      list: (input: GetUserSourceInput) => [input],
    },
  },
  operation: {
    bookings: {
      list: (input: GetCurrentUserBookingsInput) => [input],
      details: (input: GetBookingDetailsInput) => [input],
      availableHours: (input: GetBookingAvailableHours) => [input],
    },
    debt: {
      details: null,
      businessInfo: null,
    },
    rentals: {
      list: (input: GetMyRentalsInput) => [input],
      finishedList: (input: GetUserFinishedRentals) => [input],
      details: (input: GetUserRentalInput) => [input],
      businessUsageHistory: (input: GetBusinessUsageHistoryInput) => [input],
    },
    users: {
      mySubscription: (input: GetMySubscriptionInput) => [input],
      pendingBusinessSubscription: null,
    },
    subscription: {
      list: (input: GetSubscriptionsListInput) => [input],
    },
  },
  stuff: {
    booth: {
      details: (input: GetBoothDetailsInput) => [input],
      list: (input: GetBoothsListInput) => [input],
      allPods: null,
      listByGeo: (input: GetBoothsListByGeoInput) => [input],
      basicInfo: (input: GetBoothBasicInfo) => [input],
    },
  },
})
