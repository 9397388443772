import { SourceType } from 'api/unions'
import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post } from 'lib/request'

import {
  TCreditCardFinish,
  TCreditCardInit,
  TCreditCards,
} from './source.codecs'

export type GetUserSourceInput = {
  sourceType: SourceType
}

export const getUserSources = async ({ sourceType }: GetUserSourceInput) => {
  return pipe(
    await get('api/finance/me/sources', {
      query: new URLSearchParams({
        sourceType,
      }),
    }),
    decodeJson(TCreditCards),
  )
}

type CreateSourceInput = GetUserSourceInput

export const createUserSource = async ({ sourceType }: CreateSourceInput) => {
  return pipe(
    await post('api/finance/me/sources', {
      query: new URLSearchParams({
        sourceType,
      }),
    }),
    decodeJson(TCreditCardInit),
  )
}

export type UpdateUserSourceInput = {
  sourceId: UUID
  body: {
    default: boolean
    sourceType: SourceType
  }
}

export const updateUserSource = async ({
  sourceId,
  body,
}: UpdateUserSourceInput) => {
  return pipe(
    await patch('api/finance/me/sources/:sourceId', {
      params: {
        sourceId,
      },
      body,
    }),
  )
}

export type DeleteSourceInput = {
  sourceId: UUID
  sourceType: SourceType
}

export const deleteUserSource = async ({
  sourceId,
  sourceType,
}: DeleteSourceInput) => {
  return pipe(
    await del('api/finance/me/sources/:sourceId', {
      params: {
        sourceId,
      },
      query: new URLSearchParams({
        sourceType,
      }),
    }),
  )
}

export type ChallengeUserSourceInput = {
  payload: string
  paymentMethodId: string
}

export const challengeUserSource = async (input: ChallengeUserSourceInput) => {
  return pipe(
    await post('api/finance/me/sources/challenge', {
      body: input,
    }),
    decodeJson(TCreditCardFinish),
  )
}
