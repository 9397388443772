import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconArrowRightAlt = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        d="M8.40039 2.91016L15.0004 12.5102L8.40039 21.5102"
        stroke="#333333"
        stroke-miterlimit="10"
      />
    </SvgIcon>
  )
}
