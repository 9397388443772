import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconCopy = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2736 4.725H9.24844V7.09766H14.7502H16.8502V9.19766V14.803H19.2736V4.725ZM7.14844 4.725V7.09766H4.725H2.625V9.19766V19.2756V21.3756H4.725H14.7502H16.8502V19.2756V16.903H19.2736H21.3736V14.803V4.725V2.625H19.2736H9.24844H7.14844V4.725ZM7.14844 9.20117H9.24844V9.19766H14.7502V9.20117H14.9487V19.4312H4.71875V9.20117H4.725V9.19766H7.14844V9.20117Z"
        fill="#215250"
      />
    </SvgIcon>
  )
}
