import { createSvgIcon } from '@mui/material'

export const IconGoogle = createSvgIcon(
  <g>
    <path
      d="M21.3493 11.1H12.1793V13.83H18.6893C18.3593 17.64 15.1893 19.27 12.1893 19.27C8.3593 19.27 4.9993 16.25 4.9993 12C4.9993 7.9 8.1993 4.73 12.1993 4.73C15.2893 4.73 17.0993 6.7 17.0993 6.7L18.9993 4.72C18.9993 4.72 16.5593 2 12.0993 2C6.4193 2 2.0293 6.8 2.0293 12C2.0293 17.05 6.1593 22 12.2493 22C17.5993 22 21.4993 18.33 21.4993 12.91C21.4993 11.76 21.3493 11.1 21.3493 11.1Z"
      fill="#215250"
    />
  </g>,
  'IconGoogle',
)
