import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconTime = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12.7941 6.70588C12.7941 6.2673 12.4386 5.91176 12 5.91176C11.5614 5.91176 11.2059 6.2673 11.2059 6.70588V12C11.2059 12.2738 11.3469 12.5283 11.5791 12.6734L14.7556 14.6587C15.1275 14.8912 15.6174 14.7781 15.8499 14.4062C16.0823 14.0343 15.9693 13.5443 15.5974 13.3119L12.7941 11.5599V6.70588Z"
      fill="#F1691A"
    />
  </SvgIcon>
)
