import { TRegisterResult } from 'api/auth'
import { pipe } from 'fp-ts/lib/function'
import { decodeJson, get, patch, put } from 'lib/request'

import { TMyProfile } from './user.codecs'

export const getMyProfile = async () => {
  return pipe(await get('api/auth/users/me', {}), decodeJson(TMyProfile))
}

export const updateMyProfile = async (input: {
  firstName: string
  lastName: string
  companyName: string
  address: string
  phoneNumber?: string
  countryCode?: string
}) => {
  return pipe(
    await put('api/auth/users/me', { body: input }),
    decodeJson(TRegisterResult),
  )
}

export const changeMyPassword = async (input: {
  oldPassword: string
  newPassword: string
}) => {
  return await patch('api/auth/users/me/password', { body: input })
}

export const addDiscountCodeToProfile = async ({
  discountCode,
}: {
  discountCode: string
}) => {
  return pipe(
    await put('api/auth/users/me/discount', {
      query: new URLSearchParams({ discountCode }),
    }),
    decodeJson(TMyProfile),
  )
}
