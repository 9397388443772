import {
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Button } from 'components/basic/Button'
import { Handler } from 'lib/react-handy-types'
import { ReactNode } from 'react'

type Props = {
  isOpen: boolean
  onClose: (reason: 'backdropClick' | 'escapeKeyDown' | 'closeBtnClick') => void
  title: string
  text?: string
  children?: ReactNode
  onConfirm?: Handler
  confirmText?: string
  backText?: string
  disabledAction?: boolean
  variant?: 'delete' | 'confirm'
  pendingAction?: boolean
  hideActions?: boolean
  formId?: string
  width?: 'small' | 'large'
  centerTitle?: boolean
  disableClosingOnClickAway?: boolean
}

export const ConfirmationDialog = (props: Props) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      open={props.isOpen}
      onClose={(_, reason) =>
        props.disableClosingOnClickAway ? undefined : props.onClose(reason)
      }
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth:
            props.width === 'small'
              ? '312px'
              : props.width === 'large'
              ? '600px '
              : '400px',
          height: 'auto',
          borderRadius: '12px',
          width: fullScreen ? 'calc(100% - 40px)' : '100%', // @TODO: remove calc
        },
      }}
    >
      <DialogTitle
        sx={{
          pt: 2.5,
          px: 2,
          pb: 1.5,
          display: 'flex',
          justifyContent: props.centerTitle ? 'center' : 'space-between',
          alignItems: 'center',

          '& .MuiTypography-root': {
            fontWeight: '600',
            wordBreak: 'break-word',
          },
        }}
      >
        <Typography variant="body1">{props.title}</Typography>
      </DialogTitle>

      {(props.text || props.children) && (
        <DialogContent
          sx={{
            px: 2,
            pb: 3,
          }}
        >
          <DialogContentText
            sx={{
              fontWeight: 400,
              color: 'text.primary',
              marginBottom: 0,
              wordBreak: 'break-word',
            }}
          >
            {props.text}
          </DialogContentText>
          {props.children}
        </DialogContent>
      )}

      {!props.hideActions && (
        <DialogActions
          sx={{
            justifyContent: props.backText ? 'space-between' : 'flex-end',
            px: 2,
            pb: 3.5,
          }}
        >
          {props.backText && (
            <Button
              onClick={() => props.onClose('closeBtnClick')}
              variant="outlined"
              sx={{
                border: '2px solid #E5E5E5',
                color: theme.palette.text.primary,

                '&:hover': {
                  borderWidth: '2px',
                },
              }}
            >
              {props.backText}
            </Button>
          )}

          {props.onConfirm || props.confirmText || props.formId ? (
            <Button
              variant="contained"
              onClick={props.onConfirm}
              color={props.variant === 'delete' ? 'error' : 'primary'}
              disabled={props.disabledAction || false}
              pending={props.pendingAction}
              {...(props.formId && {
                type: 'submit',
                form: props.formId,
              })}
            >
              {props.confirmText}
            </Button>
          ) : null}
        </DialogActions>
      )}
    </Dialog>
  )
}
