import { Currency } from 'api/unions'
import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { decodeJson, get, post } from 'lib/request'

import { T3dsPayment } from '../bookings'
import { TDebt } from './debt.codecs'

export const getUserDebt = async () => {
  return pipe(await get('api/operation/me/debts', {}), decodeJson(TDebt))
}

export const payUserDebt = async () => {
  return pipe(await post('api/operation/me/debts', {}), decodeJson(T3dsPayment))
}

export const challengeUserDebt = async (input: { payload: string }) => {
  return await post('api/operation/me/debts/challenge', {
    body: input,
  })
}

export const getDebtInfoForBusinessAccount = async () =>
  pipe(
    await get('api/operation/me/business/debts', {}),
    decodeJson(array(TDebt)),
  )

type PayBusinessAccountDebtInput = {
  currency: Currency
}

export const payBusinessAccountDebt = async ({
  currency,
}: PayBusinessAccountDebtInput) =>
  pipe(
    await post('api/operation/me/business/debts', {
      query: new URLSearchParams({
        currency,
      }),
    }),
    decodeJson(T3dsPayment),
  )
