export const routes = {
  index: '/',
  signIn: '/sign-in',
  signUp: '/sign-up',
  forgotPassword: '/password/forgot',
  recoveryPassword: '/password/recovery',
  emailConfirmation: '/email',
  emailResend: '/email/resend',
  createPassword: '/password/new',

  myProfile: '/profile/info',
  profileCards: '/profile/cards',
  profileHistory: '/profile/history',
  rentalDetails: '/profile/history/:rentalId',
  profileSecurity: '/profile/security',
  accountCredits: '/profile/discounts',
  profileSubscription: '/profile/subscription',
  cancelledPersonalSubscription: '/profile/subscription/cancelled',

  organizationInfo: '/organization/info',
  organizationStaffAccess: '/organization/staff',
  inviteStaffMember: '/organization/staff/invite',
  organizationCards: '/organization/cards',
  organizationSubscription: '/organization/subscription',
  thankYouForContacting: '/organization/subscription/thank-you-for-contacting',
  organizationUsageHistory: '/organization/usage-history',
  cancelledOrganizationSubscription: '/organization/subscription/cancelled',

  pods: '/pods',
  podsMap: '/pods/map',
  podsList: '/pods/list',
  podUsage: '/pods/usage',
  podDetails: '/pods/:podId',
  bookAPod: '/pods/book/:podId',
  leaveFeedback: '/pods/feedback/:rentalId',

  bookings: '/bookings',
  bookingDetails: '/bookings/:bookingId',

  help: '/help',
  about: '/about',
  emailSent: '/email-sent',

  privacyPolicy: '/privacy-policy',
} as const
