import { pipe } from 'fp-ts/lib/function'
import { decodeJson, post } from 'lib/request'

import { TSuccessfulAuthResult } from '../token'

export const authBySocial = async (input: {
  client: string
  type: 'EMAIL' | 'FACEBOOK' | 'GOOGLE' | 'APPLE' | 'LINKEDIN'
  code?: string
  token?: string
}) => {
  return pipe(
    await post('api/auth/socials', { body: input }),
    decodeJson(TSuccessfulAuthResult),
  )
}
