import {
  TImageVariationRecord,
  TLocationBooth,
  TPricingShort,
} from 'api/stuff/booth'
import { TAccountType, TBoothStatus, TCurrency, TStatus } from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  string,
  type,
  TypeOf,
} from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

const TUpcomingBoothResponse = intersection([
  type({
    boothId: UUID,
    name: string,
    status: TBoothStatus,
    persons: number,
    imageVariations: array(TImageVariationRecord),
    timeZone: string,
  }),
  partial({
    city: string,
    street: string,
    number: string,
    location: TLocationBooth,
    pricing: TPricingShort,
    addressDescription: string,
    wifiNetwork: string,
    wifiPassword: string,
  }),
])

export type UpcomingPod = TypeOf<typeof TUpcomingBoothResponse>

const TRentalDetails = type({
  boothResponse: TUpcomingBoothResponse,
  currency: TCurrency,
  paid: boolean,
  pricePerQuarter: number,
  rentalId: UUID,
  rentalStatus: TStatus,
  totalPrice: number,
  accountType: TAccountType,
  discountMinutes: number,
})

export const TUpcomingBooking = partial({
  dateFrom: DateFromISOString,
  dateTo: DateFromISOString,
  rentalDetails: TRentalDetails,
  clientSecret: string,
  payload: string,
  replyTo: string,
})

export const TUpcomingBookingItem = intersection([
  type({
    bookingId: UUID,
    dateFrom: DateFromISOString,
    dateTo: DateFromISOString,
    rentalDetails: TRentalDetails,
    timeZone: string,
  }),
  partial({
    pinCode: string,
  }),
])

export type UpcomingBookingItem = TypeOf<typeof TUpcomingBookingItem>

export const TUpcomingBookingList = array(TUpcomingBookingItem)

const TAvailableHoursResponses = type({
  from: DateFromISOString,
  to: DateFromISOString,
})

export const TAvailableHours = type({
  boothTimeZone: string,
  availableHoursResponses: array(TAvailableHoursResponses),
})

export type AvailableHoursResponses = TypeOf<typeof TAvailableHoursResponses>

export type AvailableHours = TypeOf<typeof TAvailableHours>

export const T3dsPayment = partial({
  clientSecret: string,
  payload: string,
  replyTo: string,
})
export type PaymentResponse = TypeOf<typeof T3dsPayment>
