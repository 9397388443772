import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, post } from 'lib/request'

import { TFeedback } from './feedback.codecs'

export const leaveFeedback = async (input: {
  rentalId: UUID
  rate: number
  comment?: string
}) => {
  return pipe(
    await post('api/operation/rentals/:rentalId/feedbacks', {
      params: {
        rentalId: input.rentalId,
      },
      body: {
        rate: input.rate,
        comment: input.comment,
      },
    }),
    decodeJson(TFeedback),
  )
}
