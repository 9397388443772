import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconCalendar = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M9.57143 2.5H5.92857V4.9H3.5V8.5H20.5V4.9H18.0714V2.5H14.4286V4.9H9.57143V2.5Z"
      fill="#EB833D"
    />
    <path d="M20.5 10.9H3.5V20.5H20.5V10.9Z" fill="#EB833D" />
  </SvgIcon>
)
