import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconUser = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M8.65625 12.75H15.9688L19.625 16.4062V20.0625H5V16.4062L8.65625 12.75Z"
      fill="currentColor"
    />
    <path
      d="M15.9688 6.65625C15.9688 8.67554 14.3318 10.3125 12.3125 10.3125C10.2932 10.3125 8.65625 8.67554 8.65625 6.65625C8.65625 4.63696 10.2932 3 12.3125 3C14.3318 3 15.9688 4.63696 15.9688 6.65625Z"
      fill="currentColor"
    />
  </SvgIcon>
)
