import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconLock = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.375 9.125V6.75C7.375 4.12665 9.50165 2 12.125 2C14.7484 2 16.875 4.12665 16.875 6.75V9.125H19.25V21H5V9.125H7.375ZM9.75 6.75C9.75 5.43832 10.8133 4.375 12.125 4.375C13.4367 4.375 14.5 5.43832 14.5 6.75V9.125H9.75V6.75Z"
      fill="currentColor"
    />
  </SvgIcon>
)
