import { pipe } from 'fp-ts/lib/function'
import { decodeJson, post } from 'lib/request'

import { TRegisterResult } from './registration.codecs'

export const registerUser = async (input: {
  email: string
  password: string
  clientId: string
}) => {
  return pipe(
    await post('api/auth/register', {
      body: input,
    }),
    decodeJson(TRegisterResult),
  )
}
