import { UUID } from 'io-ts-types'
import { post } from 'lib/request'

export type IssueType =
  | 'UNABLE_UNLOCK'
  | 'OCCUPIED'
  | 'NEEDS_TO_BE_CLEANED'
  | 'POD_NOT_WORK_PROPERLY'
  | 'BOOKING_ISSUE'
  | 'OTHER'

export const sendIssue = async (input: {
  boothId?: UUID
  issueType: IssueType
  message: string
  userEmail: string
  phoneNumber?: string
}) => {
  return await post('api/operation/issues', {
    body: input,
  })
}
