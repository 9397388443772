import {
  TBookingAndRentalExistence,
  TMyBookingAndRentalExistence,
  TMySubscription,
  TPendingSubscriptionInfo,
} from 'api/operation/users/users.codecs'
import { AccountType } from 'api/unions'
import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, post } from 'lib/request'

export const deleteUserInfo = async () => {
  return await del('api/operation/users/me', {})
}

type CheckBusinessAccountsBookingsAndRentalInput = {
  body: {
    userIds: Array<UUID>
  }
}

export const checkBusinessMembersBookingsAndRental = async ({
  body,
}: CheckBusinessAccountsBookingsAndRentalInput) => {
  return pipe(
    await post('api/operation/businesses/me/users/info', {
      body,
    }),
    decodeJson(array(TBookingAndRentalExistence)),
  )
}

export const checkMyBookingsAndRental = async () => {
  return pipe(
    await get('api/operation/users/me/info', {}),
    decodeJson(TMyBookingAndRentalExistence),
  )
}

export type GetMySubscriptionInput = {
  accountType: AccountType
}

export const getMySubscription = async ({
  accountType,
}: GetMySubscriptionInput) => {
  return pipe(
    await get('api/operation/users/me/subscriptions/time', {
      query: new URLSearchParams({
        accountType,
      }),
    }),
    decodeJson(TMySubscription),
  )
}

export const getPendingSubscriptionInfo = async () => {
  return pipe(
    await get('api/operation/users/me/business/subscriptions/pending', {}),
    decodeJson(TPendingSubscriptionInfo),
  )
}
