import './styles.css'

import { IconButton, styled } from '@mui/material'
import { IconCross } from 'assets/icons'
import { SnackbarKey, SnackbarProvider as Provider } from 'notistack'
import { useRef } from 'react'

export const StyledProvider = styled(Provider)(({ theme }) => ({
  '&.SnackbarContent-root': {
    width: '324px',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
  },

  '&.SnackbarItem-variantSuccess': {
    background: theme.palette.success.main,
  },

  '&.SnackbarItem-variantInfo': {
    background: theme.palette.info.main,
  },

  '&.SnackbarItem-variantError': {
    background: theme.palette.error.main,
  },
}))

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const notistackRef = useRef<Provider | null>(null)

  const onClickDismiss = (key: SnackbarKey) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key)
    }
  }

  return (
    <StyledProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ref={notistackRef}
      action={key => (
        <IconButton onClick={onClickDismiss(key)}>
          <IconCross sx={{ color: '#FFF', fontSize: '16px' }} />
        </IconButton>
      )}
      preventDuplicate
    >
      {children}
    </StyledProvider>
  )
}
