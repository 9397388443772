import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconRoundCross = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12 20.994C16.9706 20.994 21 16.9673 21 12C21 7.03265 16.9706 3.00586 12 3.00586C7.02944 3.00586 3 7.03265 3 12C3 16.9673 7.02944 20.994 12 20.994Z"
      fill="#EB833D"
    />
    <path
      d="M9.14579 7.75265L7.75391 9.14453L14.8553 16.246L16.2472 14.8541L9.14579 7.75265Z"
      fill="white"
    />
    <path
      d="M14.8553 7.75207L7.75391 14.8535L9.14579 16.2454L16.2472 9.14395L14.8553 7.75207Z"
      fill="white"
    />
  </SvgIcon>
)
