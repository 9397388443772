import { createSvgIcon } from '@mui/material'

export const IconPersons = createSvgIcon(
  <g>
    <path
      d="M8 5.29167C8 6.55732 7.02062 7.58333 5.8125 7.58333C4.60438 7.58333 3.625 6.55732 3.625 5.29167C3.625 4.02601 4.60438 3 5.8125 3C7.02062 3 8 4.02601 8 5.29167Z"
      fill="#EB833D"
    />
    <path d="M2.75 9.41667L1 11.25V14H8V9.41667H2.75Z" fill="#EB833D" />
    <path d="M15 11.25L13.25 9.41667H9.75V14H15V11.25Z" fill="#EB833D" />
    <path
      d="M11.5 7.58333C12.4665 7.58333 13.25 6.76252 13.25 5.75C13.25 4.73748 12.4665 3.91667 11.5 3.91667C10.5335 3.91667 9.75 4.73748 9.75 5.75C9.75 6.76252 10.5335 7.58333 11.5 7.58333Z"
      fill="#EB833D"
    />
  </g>,
  'IconPersons',
)
