import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconArrowDown = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9141 12L9.70703 9.79289L8.29282 11.2071L11.0857 14L12.9141 14L15.707 11.2071L14.2928 9.79289L12.0857 12L11.9141 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
