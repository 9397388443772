import { createSvgIcon } from '@mui/material'

export const IconCreditCardDefault = createSvgIcon(
  <g>
    <rect
      x="1"
      y="0.771484"
      width="21"
      height="15"
      rx="2"
      fill="white"
      stroke="#E5E5E5"
    />
    <path d="M15.7014 5.06323H7.375V6.62443H15.7014V5.06323Z" fill="#215250" />
    <path d="M15.7014 7.66523H7.375V11.308H15.7014V7.66523Z" fill="#215250" />
  </g>,
  'IconCreditCardDefault',
)
