import { createSvgIcon } from '@mui/material'

export const IconTrash = createSvgIcon(
  <g>
    <path
      d="M4.125 6.02148H7.5V3.77148H16.5V6.02148H19.875V8.27148H4.125V6.02148Z"
      fill="#BF2600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.625 10.5215H6.375V21.7715H17.625V10.5215ZM13.125 13.8965H10.875V18.3965H13.125V13.8965Z"
      fill="#BF2600"
    />
  </g>,
  'IconTrash',
)
