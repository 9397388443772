import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconGooglePlay = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.3957 15.6674C20.2389 14.1063 22.3943 12.9167 22.5801 12.8242C23.1744 12.5082 23.7881 11.6717 22.5801 11.0219C22.19 10.8176 20.09 9.66505 17.3957 8.17871L13.6606 11.9508L17.3957 15.6673V15.6674Z"
        fill="#FFD900"
      />
      <path
        d="M13.6609 11.9517L1.76807 23.9379C2.04722 23.9749 2.36237 23.9009 2.73394 23.6966C3.51402 23.2696 11.7837 18.7535 17.396 15.6691L13.6609 11.9517Z"
        fill="#F43249"
      />
      <path
        d="M13.6609 11.9513L17.3959 8.19774C17.3959 8.19774 3.57036 0.652543 2.73386 0.207142C2.41871 0.0204849 2.06554 -0.0349604 1.74951 0.0204849L13.6609 11.9513V11.9513Z"
        fill="#00EE76"
      />
      <path
        d="M13.6602 11.9508L1.74888 0.0200195C1.26546 0.131786 0.856934 0.558881 0.856934 1.43234V22.5247C0.856934 23.3242 1.17305 23.9 1.76736 23.9555L13.6602 11.9508Z"
        fill="#00D3FF"
      />
    </SvgIcon>
  )
}
