import { TIdentType } from 'api/unions'
import { array, boolean, intersection, string, type, TypeOf } from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

export const TCreditCardInit = type({
  type: TIdentType,
  clientSecret: string,
  replyTo: string,
  payload: string,
})

export const TCreditCardDetails = type({
  brand: string,
  expiryMonth: string,
  expiryYear: string,
  lastFour: string,
  type: string,
})

export const TCreditCardFinish = type({
  createdAt: DateFromISOString,
  default: boolean,
  userSourceId: UUID,
  userId: UUID,
})

export const TCreditCardListItem = intersection([
  TCreditCardFinish,
  type({
    details: TCreditCardDetails,
  }),
])

export const TCreditCards = array(TCreditCardListItem)

export type CreditCards = TypeOf<typeof TCreditCards>
