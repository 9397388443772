import { array, Mixed, type, TypeOf } from 'io-ts'
import { NumberFromString } from 'io-ts-types'
import { decodeHeaders, decodeJson } from 'lib/request'

export type PaginationInput = {
  /**
   * The number of entries to skip.
   * Should be greater or equal than 0.
   */
  skip: number
  /**
   * The maximum number of entries to fetch.
   * Should be greater or equal than 0.
   */
  limit: number
}

export type SortInput = {
  /**
   * A sorting direction.
   * asc - Ascending (from lowest to highest)
   * desc - Descending (from highest to lowest)
   */
  direction: 'asc' | 'desc'
  /**
   * The name of a property to sort by.
   * Predefined on the server side.
   */
  column: string
}

const TTotalCount = type({
  /**
   * The total number of entries in the database.
   */
  count: NumberFromString,
})

export const createListQueryParams = ({
  pagination,
  sort,
}: {
  pagination?: PaginationInput
  sort?: SortInput
}) => {
  const params = new URLSearchParams()

  if (pagination) {
    params.set('skip', pagination.skip.toString())
    params.set('limit', pagination.limit.toString())
  }

  if (sort) {
    params.set('direction', sort.direction.toUpperCase())
    params.set('sort', sort.column)
  }

  return params
}

export type JsonArrayWithTotal<T> = {
  rows: Array<T>
  total: number
}

export const decodeJsonWithTotal = <TCodec extends Mixed>(codec: TCodec) => {
  type Result = JsonArrayWithTotal<TypeOf<TCodec>>

  return async (response: Response): Promise<Result> => {
    const headers = decodeHeaders(TTotalCount)(response)
    const rows = await decodeJson(array(codec))(response)
    return { rows, total: headers.count }
  }
}
