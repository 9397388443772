import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconPlus = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M14.3333 5.33301H10.3333V10.333L5.33334 10.333V14.333H10.3333V19.333H14.3333V14.333H19.3333V10.333L14.3333 10.333V5.33301Z"
      fill="currentColor"
    />
  </SvgIcon>
)
